import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Media from "./Media";

const LeftBanner = () => {
  const [text] = useTypewriter({
    words: ["Stock Market.", "Digital Marketing.", "Social Media Marketing."],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });

  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal" style={{ fontWeight: "500" }}>
          WELCOME TO AGNIMA INFOTECH
        </h4>
        <h1 className="text-4xl font-bold text-lightText">
          Unlock Your{" "}
          <span
            style={{ color: "#d62b0d" }}
            className="text-designColor capitalize"
          >
            Potential in
          </span>
        </h1>
        <h2 className="text-4xl font-bold text-lightText">
          <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          At Elite Training Institute, we empower you to thrive in today's
          dynamic market. Dive into the complexities of the stock market,
          mastering analysis and strategies for profitable trading. Elevate your
          digital marketing prowess with comprehensive courses on SEO, content
          marketing, and analytics, ensuring your brand stands out online.
          Harness the power of social media with expert training in strategy,
          content creation, and influencer marketing to engage and grow your
          audience effectively. Our expert-led programs are designed to provide
          you with the skills and knowledge needed to excel in these fields.
          Join us and transform your future today!
        </p>
      </div>
      {/* Media */}
      <Media />
    </div>
  );
};

export default LeftBanner;
