import React from "react";
import Title from "../layouts/Title";
import { projectOne, projectTwo, projectThree } from "../../assets/index";
import ProjectsCard from "./ProjectsCard";

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black "
    >
      <div className="flex justify-center items-center text-center">
        <Title
          // title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="ABOUT US"
        />
      </div>
      <div className="text-designColor">
        <p style={{ textAlign: "center" }}>
          Agnima Infotech, founded in 2024 in Bhopal, Madhya Pradesh, is a
          leading educational institution dedicated to offering expert coaching
          and comprehensive courses in stock market trading and digital
          marketing. Our mission is to empower individuals with the essential
          knowledge and practical skills necessary to excel in the dynamic
          financial and digital landscapes of today. At Agnima Infotech, we
          pride ourselves on our team of seasoned professionals who bring
          real-world experience and expertise to our curriculum. Our courses are
          meticulously designed to provide hands-on, industry-relevant training
          that ensures our students are well-prepared to meet the demands of
          their chosen fields. We emphasize practical learning and strategic
          thinking, equipping our students with the tools needed for success.
          Our commitment extends beyond just education; we aim to foster
          financial literacy and digital proficiency, enabling our students to
          achieve their career goals and attain financial independence. Agnima
          Infotech stands as a beacon of excellence, guiding individuals towards
          prosperous futures in both the stock market and digital marketing
          arenas.
        </p>
      </div>
    </section>
  );
};

export default Projects;
