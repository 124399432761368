import React from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { contactImg } from "../../assets/index";

const ContactLeft = () => {
  const handleExternalLink = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-designColor to-[#ed7c68] group   p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="w-full h-64 object-cover rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Agnima Infotech</h3>
        <p className="text-lg font-normal text-bodyColor">
          A Stock Marketing & Digital Marketing Training Agency.
        </p>
        <p className="text-base text-bodyColor flex items-center gap-2">
          Founder: <span className="text-bodyColor">Riya Gupta </span>
        </p>
        <p className="text-base text-bodyColor flex items-center gap-2">
          Address: <span className="text-bodyColor">Bhopal , MP</span>
        </p>
        {/* <p className="text-base text-gray-400 tracking-wide">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facilis
          ipsam autem cumque, accusantium dicta odio.
        </p> */}
        <p className="text-base text-bodyColor flex items-center gap-2">
          Contact Us: <span className="text-bodyColor">90982 49279</span>
        </p>
        {/* <p className="text-base text-gray-400 flex items-center gap-2">
          Email: <span className="text-lightText">noor.jsdivs@gmail.com</span>
        </p> */}
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">
          Find Us Here
        </h2>
        <div className="flex gap-4">
          <span
            className="bannerIcon"
            onClick={() =>
              handleExternalLink(
                "https://www.facebook.com/profile.php?id=61560483818270"
              )
            }
          >
            <FaFacebookF />
          </span>
          <span
            className="bannerIcon"
            onClick={() => handleExternalLink("https://x.com/AgnimaInfotech")}
          >
            <FaTwitter />
          </span>
          <span
            className="bannerIcon"
            onClick={() =>
              handleExternalLink(
                "https://www.linkedin.com/company/agnima-infotech/mycompany/?viewAsMember=true"
              )
            }
          >
            <FaLinkedinIn />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactLeft;
