import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="mt-6 lgl:mt-14 w-full h-[500px] sm:h-[700px] md:h-[600px] lgl:h-[500px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Beginner's Guide to Stock Market Investing"
            subTitle="Learn the basics of stock market investing, including key terms and concepts."
            result="Online/On-site"
            des="Understanding stocks, bonds, and mutual funds,
Introduction to stock exchanges,
Basics of reading stock charts"
          />
          <ResumeCard
            title="Advanced Technical Analysis"
            subTitle="Dive deeper into technical analysis techniques and tools."
            result="4.75/5"
            des="Chart patterns and technical indicators,
Advanced trading strategies,
Risk management practices"
          />
          <ResumeCard
            title="Professional Trading Strategies"
            subTitle="Develop and implement advanced trading strategies."
            result="USA"
            des="In-depth market analysis,
Algorithmic trading fundamentals,
Portfolio management"
          />
        </div>
      </div>
      {/* part Two */}

      {/* <div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Sr. Software Engineer"
            subTitle="Google Out Tech - (2017 - Present)"
            result="USA"
            des="Google's hiring process is an important part of our culture. Googlers care deeply about their teams and the people who make them up."
          />
        </div>
      </div> */}
    </motion.div>
  );
};

export default Education;
