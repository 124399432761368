import React, { useState } from "react";
import { Link } from "react-scroll";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { logo } from "../../assets/index";
import { logoa } from "../../assets/index";
import { navLinksdata } from "../../constants";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const handleExternalLink = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="w-full h-24 sticky top-0 z-50 bg-bodyColor mx-auto flex justify-between items-center font-titleFont border-b-[1px] border-b-gray-600 px-4">
      <div>
        <img src={logoa} alt="logo" style={{ width: "100px" }} />
      </div>
      <div>
        <ul className="hidden mdl:inline-flex items-center gap-3 lg:gap-10">
          {navLinksdata.map(({ _id, title, link }) => (
            <li
              style={{
                fontWeight: "bolder",
              }}
              className="text-base font-normal text-lightText-400 tracking-wide cursor-pointer hover:text-designColor duration-300"
              key={_id}
            >
              <Link
                // style={{ color: "#d62b0d" }}
                activeClass="active"
                to={link}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
        <span
          onClick={() => setShowMenu(!showMenu)}
          className="text-xl mdl:hidden bg-gradient-to-r from-designColor to-[#ed7c68] w-10 h-10 inline-flex items-center justify-center rounded-full text-bodyColor cursor-pointer"
        >
          <FiMenu />
        </span>
        {showMenu && (
          <div className="w-[70%] h-screen overflow-scroll absolute top-0 left-0 bg-gradient-to-r from-designColor to-[#ed7c68] p-4 scrollbar-hide">
            <div className="flex flex-col gap-8 py-2 relative">
              <div>
                <div
                  style={{
                    padding: "5px",
                    background: "#fff",
                    width: "50px",
                    borderRadius: "100%",
                  }}
                >
                  <img className="w-25" src={logoa} alt="logo" />
                </div>
                {/* <p className="text-sm text-gray-400 mt-2">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Earum soluta perspiciatis molestias enim cum repellat, magnam
                  exercitationem distinctio aliquid nam.
                </p> */}
              </div>
              <ul className="flex flex-col gap-4">
                {navLinksdata.map((item) => (
                  <li
                    key={item._id}
                    className="text-base font-normal text-bodyColor tracking-wide cursor-pointer duration-300"
                  >
                    <Link
                      onClick={() => setShowMenu(false)}
                      activeClass="active"
                      to={item.link}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="flex flex-col gap-4">
                <h2 className="text-base uppercase font-titleFont mb-4">
                  FIND US HERE
                </h2>
                <div className="flex gap-4">
                  <span
                    className="bannerIcon"
                    onClick={() =>
                      handleExternalLink(
                        "https://www.facebook.com/profile.php?id=61560483818270"
                      )
                    }
                  >
                    <FaFacebookF />
                  </span>

                  <span
                    className="bannerIcon"
                    onClick={() =>
                      handleExternalLink("https://x.com/AgnimaInfotech")
                    }
                  >
                    <FaTwitter />
                  </span>
                  <span
                    className="bannerIcon"
                    onClick={() =>
                      handleExternalLink(
                        "https://www.linkedin.com/company/agnima-infotech/mycompany/?viewAsMember=true"
                      )
                    }
                  >
                    <FaLinkedinIn />
                  </span>
                </div>
              </div>
              <span
                onClick={() => setShowMenu(false)}
                className="absolute top-4 right-4 text-bodyColor hover:text-designColor duration-300 text-2xl cursor-pointer"
              >
                <MdClose />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
