import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaGlobe } from "react-icons/fa";
import { SiProgress, SiAntdesign } from "react-icons/si";

// Features Data
export const featuresData = [
  {
    id: 1,
    title: "Stock Market Training",
    des: "Our Stock Market Training programs are designed to turn beginners into confident traders and seasoned investors.We cover Market Fundamentals,Technical Analysis,Investment Strategies,Risk Management",
  },
  {
    id: 2,
    icon: <SiProgress />,
    title: "Digital Marketing Training",
    des: "Stay ahead of the digital curve with our comprehensive Digital Marketing courses. We focus on: SEO Mastery,Content Marketing,Social Media Strategies,Analytics and Data",
  },
  {
    id: 3,
    icon: <FaMobile />,
    title: "Social Media Marketing Training",
    des: "In the era of social media, our training helps you leverage these platforms to grow your brand. Our curriculum includes: Platform Proficiency,Content Creation,Advertising,Influencer Collaboration.",
  },
];
