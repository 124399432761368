import React from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaReact } from "react-icons/fa";
import { SiTailwindcss, SiFigma, SiNextdotjs } from "react-icons/si";
const Media = () => {
  const handleExternalLink = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4">
          Find us here
        </h2>
        <div className="flex gap-4">
          <span
            className="bannerIcon"
            onClick={() =>
              handleExternalLink(
                "https://www.facebook.com/profile.php?id=61560483818270"
              )
            }
          >
            <FaFacebookF />
          </span>
          <span
            className="bannerIcon"
            onClick={() => handleExternalLink("https://x.com/AgnimaInfotech")}
          >
            <FaTwitter />
          </span>
          <span
            className="bannerIcon"
            onClick={() =>
              handleExternalLink(
                "https://www.linkedin.com/company/agnima-infotech/mycompany/?viewAsMember=true"
              )
            }
          >
            <FaLinkedinIn />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Media;
