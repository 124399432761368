import React, { useState } from "react";
import Slider from "react-slick";
import { RiStarFill } from "react-icons/ri";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi";
import Title from "../layouts/Title";
import { testimonialOne, testimonialTwo, quote } from "../../assets";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="w-14 h-12 bg-gradient-to-r from-designColor to-[#ed7c68] hover:bg-gradient-to-r from-[#ed7c68] to-designColor duration-300 rounded-md text-2xl text-bodyColor flex justify-center items-center absolute top-0 right-0  cursor-pointer z-10"
      onClick={onClick}
    >
      <HiArrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="w-14 h-12 bg-gradient-to-r from-designColor to-[#ed7c68] hover:bg-gradient-to-r from-[#ed7c68] to-designColor duration-300 rounded-md text-2xl text-bodyColor flex justify-center items-center absolute top-0 right-20  cursor-pointer z-10"
      onClick={onClick}
    >
      <HiArrowLeft />
    </div>
  );
}

const Testimonial = () => {
  const [dotActive, setDocActive] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    appendDots: (dots) => (
      <div
        style={{
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul
          style={{
            display: "flex",
            gap: "15px",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {" "}
          {dots}{" "}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === dotActive
            ? {
                width: "12px",
                height: "12px",
                color: "blue",
                background: "#d62b0d",
                borderRadius: "50%",
                cursor: "pointer",
              }
            : {
                width: "12px",
                height: "12px",
                color: "blue",
                background: "gray",
                borderRadius: "50%",
                cursor: "pointer",
              }
        }
      ></div>
    ),
  };
  return (
    <section
      id="testimonial"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title title="WHAT CLIENTS SAY" des="Testimonial" />
      </div>
      <div className="max-w-6xl mx-auto">
        {/* ================ Slider One ================== */}
        <Slider {...settings}>
          <div className="w-full">
            <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
              {/* <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-8 rounded-lg shadow-shadowOne flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">
                <img
                  className="h-72 md:h-32 lgl:h-72 rounded-lg object-cover"
                  src={testimonialOne}
                  alt="testimonialOne"
                />
                <div className="w-full flex flex-col justify-end">
                  <p className="text-xs uppercase text-designColor tracking-wide mb-2">
                    Agnima Infotech
                  </p>
                  <h3 className="text-2xl font-bold">Jone Duone Joe</h3>
                  <p className="text-base tracking-wide text-gray-500">
                    Operation Officer
                  </p>
                </div>
              </div> */}
              <div className="w-full lgl:w-[100%] h-full flex flex-col justify-between">
                <img className="w-10 lgl:w-16" src={quote} alt="quote" />
                <div className="w-full h-[70%] py-10 bg-gradient-to-r from-designColor to-[#ed7c68] rounded-lg  p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-gray-900">
                    <div>
                      <h3 className="text-xl lgl:text-2xl font-medium tracking-wide text-bodyColor">
                        Rahul Choubey, Professional Trader
                      </h3>
                      <p className="text-base text-bodyColor mt-3">
                        via Upwork - Mar 4, 2024
                      </p>
                      <div className="text-bodyColor flex gap-1">
                        <RiStarFill />
                        <RiStarFill />
                        <RiStarFill />
                        <RiStarFill />
                        <RiStarFill />
                      </div>
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-bodyColor font-medium tracking-wide leading-6">
                    Before joining Elite Training Institute, I struggled with
                    making consistent profits in the stock market. The
                    comprehensive training and personalized mentorship I
                    received transformed my approach to trading. Now, I’m
                    confidently making informed decisions and achieving my
                    financial goals. Highly recommend their Stock Market
                    Training program!
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* ================ Slider Two ================== */}

          <div className="w-full">
            <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
              {/* <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-8 rounded-lg  flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">
                <img
                  className="h-72 md:h-32 lgl:h-72 rounded-lg object-cover"
                  src={testimonialOne}
                  alt="testimonialOne"
                />
                <div className="w-full flex flex-col justify-end">
                  <p className="text-xs uppercase text-designColor tracking-wide mb-2">
                    Agnima Infotech
                  </p>
                  <h3 className="text-2xl font-bold">Jone Duone Joe</h3>
                  <p className="text-base tracking-wide text-gray-500">
                    Operation Officer
                  </p>
                </div>
              </div> */}
              <div className="w-full lgl:w-[100%] h-full flex flex-col justify-between">
                <img className="w-10 lgl:w-16" src={quote} alt="quote" />
                <div className="w-full h-[70%] py-10 bg-gradient-to-r from-designColor to-[#ed7c68] rounded-lg  p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-gray-900">
                    <div>
                      <h3 className="text-bodyColor text-xl lgl:text-2xl font-medium tracking-wide">
                        Avni Sharma, Digital Marketing Specialist
                      </h3>
                      <p className="text-base text-bodyColor mt-3">
                        via Upwork - May 1, 2024
                      </p>
                      <div className="text-bodyColor flex gap-1 ">
                        <RiStarFill />
                        <RiStarFill />
                        <RiStarFill />
                        <RiStarFill />
                        <RiStarFill />
                      </div>
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-bodyColor font-medium tracking-wide leading-6">
                    Elite Training Institute’s Digital Marketing course exceeded
                    my expectations. The hands-on projects and in-depth modules
                    on SEO and content marketing were invaluable. I was able to
                    apply what I learned immediately, and the results were
                    remarkable. My website traffic and engagement have
                    significantly improved, thanks to the expert guidance
                    provided.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* ================ Slider Three ================== */}

          <div className="w-full">
            <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
              {/* <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-8 rounded-lg  flex flex-col md:flex-row lgl:flex-col gap-8 justify-center md:justify-start lgl:justify-center">
                <img
                  className="h-72 md:h-32 lgl:h-72 rounded-lg object-cover"
                  src={testimonialOne}
                  alt="testimonialOne"
                />
                <div className="w-full flex flex-col justify-end">
                  <p className="text-xs uppercase text-designColor tracking-wide mb-2">
                    Agnima Infotech
                  </p>
                  <h3 className="text-2xl font-bold">Jone Duone Joe</h3>
                  <p className="text-base tracking-wide text-gray-500">
                    Operation Officer
                  </p>
                </div>
              </div> */}
              <div className="w-full lgl:w-[100%] h-full flex flex-col justify-between">
                <img className="w-10 lgl:w-16" src={quote} alt="quote" />
                <div className="w-full h-[70%] py-10 bg-gradient-to-r from-designColor to-[#ed7c68] rounded-lg  p-4 lgl:p-8 flex flex-col justify-center gap-4 lgl:gap-8">
                  <div className="flex flex-col justify-between lgl:items-center py-6 border-b-2 border-b-gray-900">
                    <div>
                      <h3 className="text-xl lgl:text-2xl font-medium tracking-wide text-bodyColor">
                        Ayush Niagm, Small Business Owner
                      </h3>
                      <p className="text-base text-bodyColor mt-3 ">
                        via Upwork - April 28, 2024
                      </p>
                      <div className=" flex gap-1 text-bodyColor">
                        <RiStarFill />
                        <RiStarFill />
                        <RiStarFill />
                        <RiStarFill />
                        <RiStarFill />
                      </div>
                    </div>
                  </div>
                  <p className="text-base font-titleFont text-bodyColor font-medium tracking-wide leading-6">
                    As a small business owner, I knew the importance of social
                    media but lacked the expertise to leverage it effectively.
                    Elite Training Institute’s Social Media Marketing course was
                    a game-changer. The practical tips and strategies I learned
                    helped me boost my brand’s presence and engagement on
                    multiple platforms. My business has seen a noticeable
                    increase in customer interaction and sales.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Testimonial;
