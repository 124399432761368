import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Achievement = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="mt-6 lgl:mt-14 w-full h-[500px] sm:h-[700px] md:h-[600px] lgl:h-[500px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Social Media Essentials"
            subTitle="Get started with the basics of social media marketing."
            result="Online/On-site"
            des="Overview of major social media platforms,
Creating a social media strategy,
Engaging with your audience"
          />
          <ResumeCard
            title="Content Marketing Strategies"
            subTitle="Learn how to create compelling content that engages and converts."
            result="4.75/5"
            des="Crafting effective content strategies,
Blogging, video, and social media content,
Measuring content performance"
          />
          <ResumeCard
            title="Advanced Social Media Advertising"
            subTitle="Learn to create and manage effective social media ad campaigns."
            result="USA"
            des="Facebook and Instagram ads,
Twitter and LinkedIn advertising,
Budgeting and ROI analysis
"
          />
        </div>
      </div>
      {/* part Two */}

      {/* <div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Sr. Software Engineer"
            subTitle="Google Out Tech - (2017 - Present)"
            result="USA"
            des="Google's hiring process is an important part of our culture. Googlers care deeply about their teams and the people who make them up."
          />
        </div>
      </div> */}
    </motion.div>
  );
};

export default Achievement;
